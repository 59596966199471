import { z } from "zod";
import { zodEnumSchema } from "@health/enum-options";

export const guidedCareTemplateActivitiesItemsFilterFormSchema = z.object({
  activityType: zodEnumSchema.guidedCareActivityType.nullish(),
});

export type IGuidedCareTemplateActivitiesItemsFilterFormValues = z.infer<typeof guidedCareTemplateActivitiesItemsFilterFormSchema>;

export const guidedCareTemplateActivitiesItemsFilterFormDefaultValues: Partial<IGuidedCareTemplateActivitiesItemsFilterFormValues> = {
  activityType: undefined,
};
