import { z } from "zod";
import { zodSchema } from "@health/autocompletes";
import { zodEnumSchema } from "@health/enum-options";

export const GuidedCareProgramMemberUpsertFormSchema = z.object({
  teamMemberId: z.string().optional(),
  uniqueId: z.string().optional(),
  position: zodEnumSchema.teamMemberPosition,
  specialization: zodSchema.systemCode.nullish(),
  isLicencedHealthProfessional: z.boolean(),
  isBackupPersonMandatory: z.boolean(),
  isKeyTeamMember: z.boolean(),
  templateTeamMemberId: z.string().optional(),
});

export type IGuidedCareProgramMemberUpsertFormValues = z.infer<typeof GuidedCareProgramMemberUpsertFormSchema>;

export const guidedCareProgramMemberUpsertFormDefaultValues: Partial<IGuidedCareProgramMemberUpsertFormValues> = {
  teamMemberId: undefined,
  uniqueId: undefined,
  position: undefined,
  specialization: undefined,
  isLicencedHealthProfessional: false,
  isBackupPersonMandatory: false,
  isKeyTeamMember: false,
  templateTeamMemberId: undefined,
};
