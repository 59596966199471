import { z } from "zod";
import { createZodAutocomplete } from "@toolkit/core";
import { guidedCareTemplateInterventionUpsertFormSchema } from "../TemplateInterventionUpsert/GuidedCareTemplateInterventionUpsertFormSchema";

export const guidedCareTemplateGoalRecommendationInterventionUpsertFormSchema = z.object({
  interventions: z.array(createZodAutocomplete(guidedCareTemplateInterventionUpsertFormSchema)),
});

export type IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues = z.infer<
  typeof guidedCareTemplateGoalRecommendationInterventionUpsertFormSchema
>;

export const guidedCareTemplateGoalRecommendationInterventionUpsertFormDefaultValues: Partial<IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues> =
  {
    interventions: [],
  };
