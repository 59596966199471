import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useGuidedCareTemplatesAutocompleteQuery } from "./gql";
export const createGuidedCareTemplateAutocompleteOption = (template) => {
    return createAutocompleteOption({ id: template === null || template === void 0 ? void 0 : template.id, code: template === null || template === void 0 ? void 0 : template.code, name: template === null || template === void 0 ? void 0 : template.name }, "id", "name");
};
export const getGuidedCareTemplatesAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "healthProgramTemplates",
        query: useGuidedCareTemplatesAutocompleteQuery,
        labelBy: "name",
        backendAccessor: "id",
    });
};
