import { getArticleCategoriesAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, GridImageModal, TruncateTypography } from "@toolkit/ui";
import { Article, ArticleSortingField } from "@health/queries/types";
import { useMemo } from "react";
import { ArticleRelatedArticles } from "../../components/ArticleRelatedArticles/ArticleRelatedArticles";
import { ArticlePreview } from "../../components/ArticlePreview/ArticlePreview";
import { ArticleTags } from "../../components/ArticleTags/ArticleTags";
import { ArticleActivation } from "../../components/ArticleActivation/ArticleActivation";

export const useArticleListContainerColumns = (): CustomTableColumnProps<Article>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "image",
        header: t("Image"),
        accessor: ({ headerImage }) => (
          <GridImageModal
            image={headerImage!}
            title={t("Product Image")}
            fallbackText={t("Product Image Not Provided")}
            altText={t("Product")}
          />
        ),
      },
      {
        key: "title",
        header: t("Title"),
        accessor: ({ title, titleAr }) => <TruncateTypography text={pickLocalizedValue(title!, titleAr!)} />,
        filter: {
          type: "string",
          name: "title",
        },
      },
      {
        key: "Category",
        header: t("Category"),
        accessor: ({ category }) => <TruncateTypography text={pickLocalizedValue(category?.display!, category?.displayAr!)} />,
        filter: getArticleCategoriesAutocompleteFilter({ name: "categories", multiple: true }),
      },
      {
        key: "readTimeMinutes",
        header: t("Read Time Minutes"),
        accessor: ({ readTimeMinutes }) => readTimeMinutes || "-",
      },
      {
        key: "priority",
        header: t("Priority"),
        accessor: ({ priority }) => priority || "-",
        isSortable: true,
        sortColumnEnum: ArticleSortingField.Priority,
      },
      {
        key: "relatedArticles",
        header: t("Related Articles"),
        accessor: ({ relatedArticles }) => <ArticleRelatedArticles relatedArticles={relatedArticles!} />,
      },
      {
        key: "tags",
        header: t("Tags"),
        accessor: ({ tags }) => <ArticleTags tags={tags!} />,
        filter: {
          type: "string",
          name: "tags",
          getValueForBackend: value => (value ? [value] : null),
        },
      },
      {
        key: "preview",
        header: t("Preview"),
        accessor: ({ title, description, content, contentAr }) => (
          <ArticlePreview title={title!} description={description!} content={content!} contentAr={contentAr!} />
        ),
      },
      {
        key: "activation",
        header: t("Active"),
        accessor: ({ id, active }) => <ArticleActivation id={id} isActive={!!active} />,
        filter: getAutocompleteEnumFilter("YesNo", "active"),
      },
    ];
  }, [t]);
};
