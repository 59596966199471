import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useMedicalFormsAutocompleteQuery } from "./gql";
export const createMedicalFormAutocompleteOption = (medicalForm) => {
    return createAutocompleteOption({
        id: medicalForm === null || medicalForm === void 0 ? void 0 : medicalForm.id,
        code: medicalForm === null || medicalForm === void 0 ? void 0 : medicalForm.code,
        name: medicalForm === null || medicalForm === void 0 ? void 0 : medicalForm.name,
        nameAr: medicalForm === null || medicalForm === void 0 ? void 0 : medicalForm.nameAr,
    }, "id", item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr));
};
export const getMedicalFormsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "medicalForms",
        query: useMedicalFormsAutocompleteQuery,
        labelBy: item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr),
        backendAccessor: "id",
    });
};
