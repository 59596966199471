/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
export type TemplateInterventionFragmentFragment = { __typename?: 'TemplateIntervention', id: string, name?: string | null, nameAr?: string | null, code?: string | null, category?: string | null, description?: string | null, descriptionAr?: string | null, duration?: number | null, interventionTemplateActivities?: Array<{ __typename?: 'TemplateInterventionActivity', id: string, templateActivityType?: Types.GuidedCareActivityType | null, afterCallMessageOffsetDays?: number | null, beforeCallMessageOffsetDays?: number | null, frequencyType: Types.AppointmentFrequencyType, numberOfOccurrences?: number | null, templateTeamMember?: { __typename?: 'TemplateTeamMember', id: string } | null, medicalForm?: { __typename?: 'MedicalForm', id: string, code?: string | null, name?: string | null, nameAr?: string | null } | null, medicalMessage?: { __typename?: 'MedicalMessage', id: string, code?: string | null, name?: string | null } | null, afterCallMessage?: { __typename?: 'MedicalMessage', id: string, code?: string | null, name?: string | null } | null, beforeCallMessage?: { __typename?: 'MedicalMessage', id: string, code?: string | null, name?: string | null } | null, serviceOfInterventionActivityDetails?: Array<{ __typename?: 'ServiceDetails', id?: string | null, serviceCode?: string | null, serviceName?: string | null, standard?: string | null } | null> | null } | null> | null };

export const TemplateInterventionFragmentFragmentDoc = gql`
    fragment TemplateInterventionFragment on TemplateIntervention {
  id
  name
  nameAr
  code
  category
  description
  descriptionAr
  duration
  interventionTemplateActivities {
    id
    templateActivityType
    templateTeamMember {
      id
    }
    medicalForm {
      id
      code
      name
      nameAr
    }
    medicalMessage {
      id
      code
      name
    }
    afterCallMessage {
      id
      code
      name
    }
    afterCallMessageOffsetDays
    beforeCallMessage {
      id
      code
      name
    }
    beforeCallMessageOffsetDays
    frequencyType
    numberOfOccurrences
    serviceOfInterventionActivityDetails {
      id
      serviceCode
      serviceName
      standard
    }
  }
}
    `;