import { z } from "zod";
import { zodEnumSchema } from "@health/enum-options";

export const healthConditionUpsertFormSchema = z.object({
  display: z.string().max(255),
  arabicDisplay: z.string().max(255),
  code: z.string().min(1).max(255),
  umlsCode: z.string().max(255),
  type: zodEnumSchema.healthConditionType,
  disease: z.string().max(255),
  isActive: z.boolean(),
});

export type IHealthConditionUpsertFormValues = z.infer<typeof healthConditionUpsertFormSchema>;

export const healthConditionUpsertFormDefaultValues: Partial<IHealthConditionUpsertFormValues> = {
  display: undefined,
  arabicDisplay: undefined,
  code: undefined,
  umlsCode: undefined,
  type: undefined,
  disease: undefined,
  isActive: false,
};
