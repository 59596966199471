import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useArticleCategoriesAutocompleteQuery } from "./gql";
export const createArticleCategoryAutocompleteOption = (articleCategory) => {
    return createAutocompleteOption({
        id: articleCategory === null || articleCategory === void 0 ? void 0 : articleCategory.id,
        code: articleCategory === null || articleCategory === void 0 ? void 0 : articleCategory.code,
        display: articleCategory === null || articleCategory === void 0 ? void 0 : articleCategory.display,
        displayAr: articleCategory === null || articleCategory === void 0 ? void 0 : articleCategory.displayAr,
    }, "id", item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.display, item === null || item === void 0 ? void 0 : item.displayAr));
};
export const getArticleCategoriesAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "articleCategories",
        query: useArticleCategoriesAutocompleteQuery,
        labelBy: item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.display, item === null || item === void 0 ? void 0 : item.displayAr),
        backendAccessor: "id",
    });
};
