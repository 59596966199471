import { z } from "zod";
import { zodSchema } from "@health/autocompletes";
import { zodEnumSchema } from "@health/enum-options";

export const GuidedCareProgramActivityServiceUpsertFormSchema = z.object({
  serviceId: z.string().optional(),
  standard: zodEnumSchema.systemCode,
  service: zodSchema.systemCode,
});

export type IGuidedCareProgramActivityServiceUpsertFormValues = z.infer<typeof GuidedCareProgramActivityServiceUpsertFormSchema>;

export const guidedCareProgramActivityServiceUpsertFormDefaultValues: Partial<IGuidedCareProgramActivityServiceUpsertFormValues> = {
  serviceId: undefined,
  standard: undefined,
  service: undefined,
};
