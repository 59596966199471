/* eslint-disable max-lines */
import {
  getGuidedCareProgramTeamsAutocompleteFilter,
  getGuidedCareTemplatesAutocompleteFilter,
  getHealthConditionsAutocompleteFilter,
  getHealthProgramMemberListsAutocompleteFilter,
  getHealthProgramNetworksAutocompleteFilter,
  getPayersAutocompleteFilter,
  getVendorsAutocompleteFilter,
} from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { GuidedCareHealthProgram, GuidedCareHealthProgramSortingField, H_OrderDirection } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, ShowButton, useFormatDate } from "@toolkit/ui";
import { guidedCareProgramsPaths } from "pages/Programs/constants";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { GuidedCareProgramActivation } from "../../components/ProgramActivation/GuidedCareProgramActivation";

export const useGuidedCareProgramListColumns = (): CustomTableColumnProps<GuidedCareHealthProgram>[] => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const { formatLocalizedDate } = useFormatDate();

  const onProviderCareTeamsClick = useCallback(
    (programId: string) => {
      return () => {
        navigate(guidedCareProgramsPaths.teamList.fullPathWithParams({ programId }));
      };
    },
    [navigate]
  );

  return useMemo(() => {
    return [
      {
        key: "id",
        header: t("id-as-number"),
        accessor: "id",
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: GuidedCareHealthProgramSortingField.Id,
      },
      {
        key: "program",
        header: t("Program"),
        accessor: "name",
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: GuidedCareHealthProgramSortingField.ProgramName,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "template",
        header: t("Template"),
        accessor: ({ guidedCareHealthProgramTemplate }) => guidedCareHealthProgramTemplate?.name,
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: GuidedCareHealthProgramSortingField.TemplateName,
        filter: getGuidedCareTemplatesAutocompleteFilter({ name: "templateId" }),
      },
      {
        key: "provider",
        header: t("Provider"),
        showOnlyForFilterField: true,
        filter: getVendorsAutocompleteFilter({ name: "providerId" }),
      },
      {
        key: "price",
        header: t("Price"),
        accessor: "price",
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: GuidedCareHealthProgramSortingField.Price,
      },
      {
        key: "fromPrice",
        header: t("From Price"),
        showOnlyForFilterField: true,
        filter: {
          type: "number",
          name: "price.gte",
        },
      },
      {
        key: "toPrice",
        header: t("To Price"),
        showOnlyForFilterField: true,
        filter: {
          type: "number",
          name: "price.lte",
        },
      },
      {
        key: "healthCondition",
        header: t("Health Condition"),
        showOnlyForFilterField: true,
        filter: getHealthConditionsAutocompleteFilter({ name: "healthConditionId" }),
      },
      {
        key: "payer",
        header: t("Payer"),
        showOnlyForFilterField: true,
        filter: getPayersAutocompleteFilter({ name: "payerId" }),
      },
      {
        key: "careTeams",
        header: t("Care Teams"),
        accessor: ({ id }) => <ShowButton onClick={onProviderCareTeamsClick(id)} />,
        filter: getGuidedCareProgramTeamsAutocompleteFilter({ name: "careTeams", multiple: true }),
      },
      {
        key: "insuranceNetworks",
        header: t("Insurance Networks"),
        showOnlyForFilterField: true,
        filter: getHealthProgramNetworksAutocompleteFilter({ name: "insuranceNetworks", multiple: true }),
      },
      {
        key: "members",
        header: t("Members"),
        showOnlyForFilterField: true,
        filter: getHealthProgramMemberListsAutocompleteFilter({ name: "memberLists", multiple: true }),
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        accessor: ({ createdDate }) => formatLocalizedDate(createdDate),
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: GuidedCareHealthProgramSortingField.Created,
      },
      {
        key: "lastModifiedDate",
        header: t("Last Modified Date"),
        accessor: ({ lastModifiedDate }) => formatLocalizedDate(lastModifiedDate),
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: GuidedCareHealthProgramSortingField.Modified,
      },
      {
        key: "isActive",
        header: t("Active"),
        type: "string",
        accessor: ({ id, isActive }) => <GuidedCareProgramActivation id={id} isActive={!!isActive} />,
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: GuidedCareHealthProgramSortingField.IsActive,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ];
  }, [formatLocalizedDate, onProviderCareTeamsClick, t]);
};
