import { z } from "zod";
import { zodSchema } from "@health/autocompletes";
import { zodEnumSchema } from "@health/enum-options";

export const medicalMessageUpsertFormSchema = z.object({
  name: z.string().min(1).max(255),
  code: z.string().min(1).max(255),
  medicalMessageType: zodEnumSchema.medicalMessageType,
  medicalMessageCategory: zodEnumSchema.medicalMessageCategory,
  healthCondition: zodSchema.healthCondition.nullish(),
  headerImage: z.string(),
});

export type IMedicalMessageUpsertFormValues = z.infer<typeof medicalMessageUpsertFormSchema>;

export const medicalMessageDefaultValues: Partial<IMedicalMessageUpsertFormValues> = {
  name: undefined,
  code: undefined,
  medicalMessageType: undefined,
  medicalMessageCategory: undefined,
  healthCondition: undefined,
  headerImage: undefined,
};
