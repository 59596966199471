import { z } from "zod";
import { zodSchema } from "@health/autocompletes";

export const riskFactorTemplateParameterCreateFormSchema = z.object({
  healthParameter: zodSchema.healthParameter,
});

export type IRiskFactorTemplateParameterCreateFormValues = z.infer<typeof riskFactorTemplateParameterCreateFormSchema>;

export const riskFactorTemplateParameterCreateFormDefaultValues: Partial<IRiskFactorTemplateParameterCreateFormValues> = {
  healthParameter: undefined,
};
