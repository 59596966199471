import { HealthParameter, HealthParameterInput, HealthParameterUpdateInput } from "@health/queries/types";
import { chartTypeOptionsMap, fieldStandardOptionsMap, templateFieldTypeOptionsMap } from "@health/enum-options";
import { IHealthParameterUpsertFormValues } from "../forms/HealthParameterUpsert/HealthParameterUpsertFormSchema";
import { createAutocompleteOption, createAutocompleteStringOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";

export const convertHealthParameterToFormValues = (healthParameter: HealthParameter): IHealthParameterUpsertFormValues => {
  const category = createAutocompleteOption(
    {
      id: healthParameter?.category?.id!,
      code: healthParameter?.category?.code!,
      display: healthParameter?.category?.display!,
      arabicDisplay: healthParameter?.category?.arabicDisplay!,
    },
    "id",
    item => pickLocalizedValue(item?.display, item?.arabicDisplay || item?.display)
  );

  const unit = healthParameter?.unit
    ? createAutocompleteOption(
        {
          id: healthParameter?.unit?.id!,
          code: healthParameter?.unit?.code!,
          display: healthParameter?.unit?.display!,
          arabicDisplay: healthParameter?.unit?.arabicDisplay!,
        },
        "id",
        item => pickLocalizedValue(item?.display, item?.arabicDisplay || item?.display)
      )
    : undefined;

  const standardCode = createAutocompleteOption(
    {
      code: healthParameter?.standardCode!,
      display: healthParameter?.standardCodeDisplay!,
    },
    "code",
    "display"
  );

  return {
    code: healthParameter?.code!,
    display: healthParameter?.display!,
    arabicDisplay: healthParameter?.arabicDisplay!,
    category,
    unit,
    type: templateFieldTypeOptionsMap[healthParameter?.type!],
    chartType: chartTypeOptionsMap[healthParameter?.chartType!],
    standard: fieldStandardOptionsMap[healthParameter?.standard!],
    standardCode,
    allowedValues: (healthParameter?.allowedValues || []) as string[],
    defaultValue: healthParameter?.defaultValue ? createAutocompleteStringOption(healthParameter?.defaultValue) : undefined,
    referenceRange: healthParameter?.referenceRange,
    minNormalRangeValue: healthParameter?.minNormalRangeValue,
    maxNormalRangeValue: healthParameter?.maxNormalRangeValue,
    isActive: !!healthParameter?.isActive,
    isMandatory: !!healthParameter?.isMandatory,
    isVisibleForPatient: !!healthParameter?.visibleForPatientOverview,
  };
};

export const convertHealthParameterFormValuesToBackEndValues = (values: IHealthParameterUpsertFormValues): HealthParameterInput => {
  return {
    code: values.code,
    display: values.display,
    arabicDisplay: values.arabicDisplay,
    category: {
      id: values.category?.value?.id!,
    },
    unit: values.unit?.value?.id
      ? {
          id: values.unit?.value?.id,
        }
      : undefined,
    type: values?.type?.value,
    chartType: values?.chartType?.value,
    allowedValues: values?.allowedValues,
    defaultValue: values?.defaultValue?.value,
    minNormalRangeValue: values?.minNormalRangeValue,
    maxNormalRangeValue: values?.maxNormalRangeValue,
    standard: values?.standard?.value,
    standardCode: values?.standardCode?.value?.code!,
    referenceRange: values?.referenceRange,
    isActive: values?.isActive,
    isMandatory: values?.isMandatory,
    visibleForPatientOverview: values?.isVisibleForPatient,
  };
};
export const convertHealthParameterUpdateFormValuesToBackEndValues = (
  values: IHealthParameterUpsertFormValues
): HealthParameterUpdateInput => {
  return {
    display: values.display,
    arabicDisplay: values.arabicDisplay,
    category: {
      id: values.category?.value?.id!,
    },
    unit: values.unit?.value?.id
      ? {
          id: values.unit?.value?.id,
        }
      : undefined,
    chartType: values?.chartType?.value,
    defaultValue: values?.defaultValue?.value,
    minNormalRangeValue: values?.minNormalRangeValue,
    maxNormalRangeValue: values?.maxNormalRangeValue,
    standard: values?.standard?.value,
    standardCode: values?.standardCode?.value?.code!,
    referenceRange: values?.referenceRange,
    isActive: values?.isActive,
    isMandatory: values?.isMandatory,
    visibleForPatientOverview: values?.isVisibleForPatient,
  };
};
