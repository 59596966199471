import { toSentenceCase } from "@toolkit/core";
import { mapToAutocompleteOptions } from "@toolkit/ui";
import {
  guidedCareActivityTypeToTeamMemberPositionMap,
  isGuidedCareActivityTypeMedicalMessage,
  isTeamMemberNonManagement,
} from "@health/enum-options";
import { GuidedCareActivityType, MedicalMessageCategory } from "@health/queries/types";
import { IGuidedCareTemplateMemberUpsertFormValues } from "../forms/TemplateMemberUpsert/GuidedCareTemplateMemberUpsertFormSchema";
import { IGuidedCareTemplateGoalUpsertFormValues } from "../forms/TemplateGoalUpsert/GuidedCareTemplateGoalUpsertFormSchema";
import { IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues } from "../forms/TemplateGoalRecommendationInterventionUpsert/GuidedCareTemplateGoalRecommendationInterventionUpsertFormSchema";
import { IGuidedCareTemplateGoalRecommendationUpsertFormValues } from "../forms/TemplateGoalRecommendationUpsert/GuidedCareTemplateGoalRecommendationUpsertFormSchema";
import {
  IGuidedCareTemplateActivitiesItem,
  IGuidedCareTemplateActivityItem,
  IGuidedCareTemplateInterventionActivitiesField,
} from "@/pages/Templates/types";
import { IGuidedCareTemplateInterventionActivityUpsertFormValues } from "@/pages/Templates/forms/TemplateInterventionActivityUpsert/GuidedCareTemplateInterventionActivityUpsertFormSchema";

export const getTeamMemberLabel = (teamMember: IGuidedCareTemplateMemberUpsertFormValues) => {
  const teamMemberSpecialization =
    isTeamMemberNonManagement(teamMember?.position?.value!) && teamMember?.specialization?.label
      ? ` - ${toSentenceCase(teamMember?.specialization?.label)}`
      : "";

  return `${teamMember?.position?.label}${teamMemberSpecialization}`;
};

export const filterTeamMembersByActivityType = (
  teamMembers: IGuidedCareTemplateMemberUpsertFormValues[],
  activityType: GuidedCareActivityType
) => {
  const activityTypePositions = guidedCareActivityTypeToTeamMemberPositionMap[activityType] || [];
  const filteredTeamMembers = teamMembers?.filter(item => activityTypePositions.includes(item?.position?.value!)) || [];

  return mapToAutocompleteOptions(filteredTeamMembers, "uniqueId", getTeamMemberLabel);
};

export const getMedicalMessageCategory = (medicalMessage: string) => {
  if (!medicalMessage) return undefined;

  const medicalMessageCategory = medicalMessage?.replace("MEDICAL_MESSAGE_", "");

  return Object.values(MedicalMessageCategory).find(value => value === medicalMessageCategory);
};

export const shouldTemplateActivityMessagesAndOffsetsShow = (templateActivityType: GuidedCareActivityType) => {
  return templateActivityType !== GuidedCareActivityType.MedicalForm && !isGuidedCareActivityTypeMedicalMessage(templateActivityType);
};

export const createNewTemplateRecommendation = (
  goals: IGuidedCareTemplateGoalUpsertFormValues[],
  selectedGoalIndex: number,
  newRecommendation: IGuidedCareTemplateGoalRecommendationUpsertFormValues
) => {
  return (
    goals?.map((goal, goalIndex) => {
      if (goalIndex !== selectedGoalIndex) return goal;

      return {
        ...goal,
        recommendations: [...goal.recommendations!, newRecommendation],
      };
    }) || []
  );
};

export const updateTemplateRecommendation = (
  goals: IGuidedCareTemplateGoalUpsertFormValues[],
  selectedGoalIndex: number,
  selectedRecommendationIndex: number,
  updatedRecommendation: IGuidedCareTemplateGoalRecommendationUpsertFormValues
) => {
  return (
    goals.map((goal, goalIndex) => {
      if (goalIndex !== selectedGoalIndex) return goal;

      const updatedRecommendations = goal.recommendations?.map((recommendation, recommendationIndex) =>
        recommendationIndex === selectedRecommendationIndex ? updatedRecommendation : recommendation
      );

      return {
        ...goal,
        recommendations: updatedRecommendations,
      };
    }) || []
  );
};

export const updateTemplateRecommendationIntervention = (
  goals: IGuidedCareTemplateGoalUpsertFormValues[],
  selectedGoalIndex: number,
  selectedRecommendationIndex: number,
  newInterventions: IGuidedCareTemplateGoalRecommendationInterventionUpsertFormValues
) => {
  return goals.map((goal, goalIndex) => {
    if (goalIndex !== selectedGoalIndex) return goal;

    const updatedRecommendations = goal.recommendations?.map((recommendation, recommendationIndex) => {
      if (recommendationIndex !== selectedRecommendationIndex) return recommendation;

      return { ...recommendation, interventions: newInterventions?.interventions || [] };
    });

    return { ...goal, recommendations: updatedRecommendations };
  });
};

export const removeTemplateGoal = (goals: IGuidedCareTemplateGoalUpsertFormValues[], goalIndex: number) => {
  return goals.filter((_, index) => index !== goalIndex);
};

export const removeTemplateRecommendation = (
  goals: IGuidedCareTemplateGoalUpsertFormValues[],
  goalIndex: number,
  recommendationIndex: number
) => {
  return goals.map((goal, index) => {
    if (index !== goalIndex) return goal;

    const updatedRecommendations = goal.recommendations?.filter((_, recIndex) => recIndex !== recommendationIndex);

    return {
      ...goal,
      recommendations: updatedRecommendations,
    };
  });
};

export const getGuidedCareTemplateActivitiesItemProperties = (
  item:
    | IGuidedCareTemplateActivityItem
    | IGuidedCareTemplateActivitiesItem
    | IGuidedCareTemplateInterventionActivitiesField
    | IGuidedCareTemplateInterventionActivityUpsertFormValues
) => {
  if (item?.medicalMessage) {
    return { name: item?.medicalMessage?.value?.name!, code: item?.medicalMessage?.value?.code! };
  } else if (item?.medicalForm) {
    return { name: item?.medicalForm?.value?.name!, code: item?.medicalForm?.value?.code! };
  }
};
