import { i18n } from "@toolkit/i18n";
import { IEnumToOptionsMap } from "@health/enum-options";

export enum GuidedCareActivityDurationPeriod {
  Annual = "Annual",
  Quarterly = "Quarterly",
}

export const guidedCareActivityDurationPeriodOptionsMap: IEnumToOptionsMap<GuidedCareActivityDurationPeriod> = {
  [GuidedCareActivityDurationPeriod.Annual]: {
    key: GuidedCareActivityDurationPeriod.Annual,
    get label() {
      return i18n.t("Annual", { ns: "domains" });
    },
    value: GuidedCareActivityDurationPeriod.Annual,
  },
  [GuidedCareActivityDurationPeriod.Quarterly]: {
    key: GuidedCareActivityDurationPeriod.Quarterly,
    get label() {
      return i18n.t("3 Months", { ns: "domains" });
    },
    value: GuidedCareActivityDurationPeriod.Quarterly,
  },
};

export const guidedCareActivityDurationPeriodOptions = Object.values(guidedCareActivityDurationPeriodOptionsMap);
