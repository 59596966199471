import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useMedicalMessagesAutocompleteQuery } from "./gql";
export const createMedicalMessageAutocompleteOption = (medicalMessage) => {
    return createAutocompleteOption({
        id: medicalMessage === null || medicalMessage === void 0 ? void 0 : medicalMessage.id,
        code: medicalMessage === null || medicalMessage === void 0 ? void 0 : medicalMessage.code,
        name: medicalMessage === null || medicalMessage === void 0 ? void 0 : medicalMessage.name,
    }, "id", item => `${item === null || item === void 0 ? void 0 : item.code} - ${item === null || item === void 0 ? void 0 : item.name}`);
};
export const getMedicalMessagesAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "medicalMessages",
        query: useMedicalMessagesAutocompleteQuery,
        labelBy: "name",
        backendAccessor: "id",
    });
};
