import { z } from "zod";
import { zodSchema } from "@health/autocompletes";

export const labTemplateUpsertFormSchema = z.object({
  display: z.string().max(255),
  arabicDisplay: z.string().max(255),
  code: zodSchema.systemCode,
  isActive: z.boolean(),
});

export type ILabTemplateUpsertFormValues = z.infer<typeof labTemplateUpsertFormSchema>;

export const labTemplateUpsertFormDefaultValues: Partial<ILabTemplateUpsertFormValues> = {
  display: undefined,
  arabicDisplay: undefined,
  code: undefined,
  isActive: false,
};
