import { createAutocompleteOption, getAutocompleteFilter } from "@toolkit/ui";
import { useGuidedCareTeamsAutocompleteQuery } from "./gql";
export const createGuidedCareTeamAutocompleteOption = (team) => {
    return createAutocompleteOption({ id: team === null || team === void 0 ? void 0 : team.id, name: team === null || team === void 0 ? void 0 : team.name }, "id", "name");
};
export const getGuidedCareProgramTeamsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "providerGuidedCareHealthProgramTeams",
        query: useGuidedCareTeamsAutocompleteQuery,
        labelBy: "name",
        backendAccessor: "id",
        filterSearchKey: "name",
    });
};
