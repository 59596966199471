import { getAutocompleteEnumFilter } from "@health/enum-options";
import { HealthCondition, HealthConditionSortingField, SortDirection } from "@health/queries/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Button, CustomIcon, CustomTableColumnProps, ShowButton, TruncateTypography, formatDate } from "@toolkit/ui";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { convertToTitleCase } from "utils";
import { healthConditionsPaths } from "../../constants";
import { HealthConditionActivation } from "../../components/HealthConditionActivation/HealthConditionActivation";

export const useHealthConditionListContainerColumns = (): CustomTableColumnProps<HealthCondition>[] => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const onShowParametersClick = useCallback(
    (id: string) => {
      navigate(healthConditionsPaths.parameterList.fullPathWithParams({ healthConditionId: id }));
    },
    [navigate]
  );

  const onShowDiagnosesClick = useCallback(
    (id: string) => {
      navigate(healthConditionsPaths.diagnosisList.fullPathWithParams({ healthConditionId: id }));
    },
    [navigate]
  );

  const onCloneClick = useCallback(
    (id: string) => {
      navigate(healthConditionsPaths.clone.fullPathWithParams({ healthConditionId: id }));
    },
    [navigate]
  );

  return useMemo(() => {
    return [
      {
        key: "code",
        header: t("Code"),
        accessor: ({ code }) => <TruncateTypography text={code || "-"} />,
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: HealthConditionSortingField.Code,
        filter: {
          type: "string",
          name: "code",
        },
      },
      {
        key: "display",
        header: t("risk-display"),
        accessor: ({ display, arabicDisplay }) => <TruncateTypography text={pickLocalizedValue(display!, arabicDisplay!)} />,
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: pickLocalizedValue(HealthConditionSortingField.Display, HealthConditionSortingField.ArabicDisplay),
        filter: {
          type: "string",
          name: pickLocalizedValue("display", "arabicDisplay"),
        },
      },
      {
        key: "ArabicDisplay",
        header: t("risk-arabic-display"),
        filter: {
          type: "string",
          name: "arabicDisplay",
        },
        showOnlyForFilterField: true,
        hideFromSettings: true,
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => t(convertToTitleCase(type || "-")),
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: HealthConditionSortingField.Type,
        filter: getAutocompleteEnumFilter("HealthConditionType", "type"),
      },
      {
        key: "disease",
        header: t("Disease"),
        accessor: ({ disease }) => <TruncateTypography text={disease || "-"} />,
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: HealthConditionSortingField.Disease,
        filter: {
          type: "string",
          name: "disease",
        },
      },
      {
        key: "umlsCode",
        header: t("UMLS Code"),
        accessor: ({ umlsCode }) => <TruncateTypography text={umlsCode || "-"} />,
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        accessor: ({ createdDate }) => formatDate(createdDate!),
        type: "string",
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: HealthConditionSortingField.CreatedDate,
      },
      {
        key: "Parameters",
        header: t("Parameters"),
        accessor: ({ id }) => <ShowButton variant={"text"} onClick={() => onShowParametersClick(id!)} />,
      },
      {
        key: "diagnosis",
        header: t("Diagnosis"),
        accessor: ({ id }) => <ShowButton variant={"text"} onClick={() => onShowDiagnosesClick(id!)} />,
      },
      {
        key: "Clone",
        header: t("Clone"),
        accessor: ({ id }) => (
          <Button startIcon={<CustomIcon icon={"clone"} />} variant={"text"} onClick={() => onCloneClick(id!)}>
            {t("Clone")}
          </Button>
        ),
      },
      {
        key: "active",
        header: t("Active"),
        accessor: ({ id, isActive }) => <HealthConditionActivation id={id!} isActive={!!isActive} />,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ];
  }, [onCloneClick, onShowDiagnosesClick, onShowParametersClick, t]);
};
