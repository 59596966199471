import { z } from "zod";
import { createZodAutocomplete } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { zodSchema } from "@health/autocompletes";
import { HealthConditionDiagnoseCreationMethod } from "../../types";

export const healthConditionDiagnoseCreateFormSchema = z
  .object({
    creationMethod: createZodAutocomplete(HealthConditionDiagnoseCreationMethod),
    diagnose: zodSchema.systemCode.nullish(),
    disease: z.string().nullish(),
  })
  .superRefine((data, ctx) => {
    if (data.creationMethod?.value === HealthConditionDiagnoseCreationMethod.Diagnose && !data.diagnose) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "gcadmin" }),
        path: ["diagnose"],
      });
    } else if (data.creationMethod?.value === HealthConditionDiagnoseCreationMethod.Disease && !data.disease) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "gcadmin" }),
        path: ["disease"],
      });
    }
  });

export type IHealthConditionDiagnoseCreateFormValues = z.infer<typeof healthConditionDiagnoseCreateFormSchema>;

export const healthConditionDiagnoseCreateFormDefaultValues: Partial<IHealthConditionDiagnoseCreateFormValues> = {
  creationMethod: undefined,
  diagnose: undefined,
  disease: undefined,
};
