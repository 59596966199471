import { FC } from "react";
import { Box, Typography } from "@toolkit/ui";
import { getProviderGuidedCareTeamStatusColor, providerGuidedCareTeamStatusOptionsMap } from "@health/enum-options";
import { ProviderGuidedCareTeamStatus } from "@health/queries/types";
import { useGuidedCareProgramTeamStatusStyle } from "./useGuidedCareProgramTeamStatusStyle";

type GuidedCareProgramCareTeamStatusProps = {
  teamStatus: ProviderGuidedCareTeamStatus;
};

export const GuidedCareProgramTeamStatus: FC<GuidedCareProgramCareTeamStatusProps> = props => {
  const { teamStatus } = props;

  const { classes, theme } = useGuidedCareProgramTeamStatusStyle();

  const statusColor = getProviderGuidedCareTeamStatusColor(teamStatus, theme);

  return (
    <Box className={classes.root}>
      <Box className={classes.circle} sx={{ backgroundColor: statusColor }}></Box>
      <Typography color={statusColor}> {providerGuidedCareTeamStatusOptionsMap[teamStatus]?.label} </Typography>
    </Box>
  );
};
