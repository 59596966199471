import { guidedCareActivityTypeOptionsMap, teamMemberPositionOptionsMap } from "@health/enum-options";
import { TemplateActivityEventItem } from "@health/queries/types";
import { useCustomForm, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomTable, FormActions, FormCard, Grid, PageWrapper, Typography } from "@toolkit/ui";
import { convertTemplateActivityItemsToFormValues } from "pages/Templates/others";
import {
  IGuidedCareActivityItemUpdateFormPropsEvent,
  IGuidedCareActivityItemsFormEvent,
  IGuidedCareActivityItemsItem,
  IGuidedCareTemplateActivity,
} from "pages/Templates/types";
import { FC, useEffect, useState } from "react";
import { FormProvider, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { GuidedCareTemplateActivityItemUpdateModal } from "../../modals/TemplateActivityItemUpdate/GuidedCareTemplateActivityItemUpdateModal";
import { useGuidedCareTemplateActivityItemsColumns } from "../TemplateActivityItems/useGuidedCareTemplateActivityItemsColumns";
import { useGuidedCareTemplateActivityItemsFormStyle } from "../TemplateActivityItems/useGuidedCareTemplateActivityItemsFormStyle";
import {
  GuidedCareTemplateActivityItemsFormSchema,
  IGuidedCareActivityItemsFormValues,
  guidedCareActivityItemsFormDefaultValues,
} from "./GuidedCareTemplateActivityItemsFormSchema";

type GuidedCareActivityItemsFormProps = {
  isLoading?: boolean;
  activity: IGuidedCareTemplateActivity;
  onChange: (event: IGuidedCareActivityItemsFormEvent) => void;
};

export const GuidedCareTemplateActivityItemsForm: FC<GuidedCareActivityItemsFormProps> = props => {
  const { isLoading, activity, onChange } = props;

  const [selectedActivityItem, setSelectedActivityItem] = useState<IGuidedCareActivityItemsItem>();

  const { open, handleOpen, handleClose } = useOpenState();
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const { classes } = useGuidedCareTemplateActivityItemsFormStyle();

  const form = useCustomForm<IGuidedCareActivityItemsFormValues>({
    defaultValues: guidedCareActivityItemsFormDefaultValues,
    schema: GuidedCareTemplateActivityItemsFormSchema,
  });

  const { control, setValue, handleSubmit } = form;

  const { fields, update } = useFieldArray<IGuidedCareActivityItemsFormValues, "activityItems">({
    control,
    name: "activityItems",
  });

  const onSubmit = (values: IGuidedCareActivityItemsFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const onActivityItemUpdateModalChange = (event: IGuidedCareActivityItemUpdateFormPropsEvent) => {
    if (event.type === "UPDATE" && selectedActivityItem) {
      const updatedFieldIndex = fields.findIndex(item => item.id === selectedActivityItem.id);
      if (updatedFieldIndex > -1) {
        update(updatedFieldIndex, event.payload.values);
      }
      handleClose();
    }
  };

  const onEditRowClick = (item: IGuidedCareActivityItemsItem) => {
    setSelectedActivityItem(item);
    handleOpen();
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (activity?.templateActivityEventItems) {
      const activityItems = convertTemplateActivityItemsToFormValues(activity?.templateActivityEventItems as TemplateActivityEventItem[]);

      setValue("activityItems", activityItems);
    }
  }, [activity?.templateActivityEventItems, setValue]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions hasCancel hasFormButton formButtonTitle={t("Save")} onNavigation={handleCancel} newButtonDisabled={isLoading} />
          }
        >
          <FormCard title={t("Activity Items")} loading={isLoading} doYouHaveData>
            <Grid container spacing={2}>
              {open && (
                <GuidedCareTemplateActivityItemUpdateModal
                  isOpen={open}
                  onClose={handleClose}
                  activityItem={selectedActivityItem}
                  onChange={onActivityItemUpdateModalChange}
                />
              )}

              <Grid item xs={12}>
                <Typography className={classes.subtitle}>
                  {t("Team Member")}: {teamMemberPositionOptionsMap[activity?.templateTeamMember?.position!]?.label!}
                </Typography>

                <Typography className={classes.subtitle}>
                  {t("Activity Type")}: {guidedCareActivityTypeOptionsMap[activity?.templateActivityType!]?.label}
                </Typography>

                {activity?.medicalMessage && (
                  <Typography className={classes.subtitle}>
                    {t("Medical Message")}: {`${activity?.medicalMessage?.code} - ${activity?.medicalMessage?.name}`}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <CustomTable
                  data={fields}
                  columns={useGuidedCareTemplateActivityItemsColumns()}
                  isEditVisible
                  isRowEditable
                  onEditRow={onEditRowClick}
                  hasFooter={false}
                  scrollable
                />
              </Grid>
            </Grid>
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
