/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProgramGetNameQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ProgramGetNameQuery = { __typename?: 'Query', guidedCareHealthProgram?: { __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null } | null };


export const ProgramGetNameDocument = gql`
    query ProgramGetName($id: ID!) {
  guidedCareHealthProgram(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useProgramGetNameQuery__
 *
 * To run a query within a React component, call `useProgramGetNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramGetNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramGetNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProgramGetNameQuery(baseOptions: Apollo.QueryHookOptions<ProgramGetNameQuery, ProgramGetNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProgramGetNameQuery, ProgramGetNameQueryVariables>(ProgramGetNameDocument, options);
      }
export function useProgramGetNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProgramGetNameQuery, ProgramGetNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProgramGetNameQuery, ProgramGetNameQueryVariables>(ProgramGetNameDocument, options);
        }
export type ProgramGetNameQueryHookResult = ReturnType<typeof useProgramGetNameQuery>;
export type ProgramGetNameLazyQueryHookResult = ReturnType<typeof useProgramGetNameLazyQuery>;
export type ProgramGetNameQueryResult = Apollo.QueryResult<ProgramGetNameQuery, ProgramGetNameQueryVariables>;