import { z } from "zod";
import { zodSchema } from "@health/autocompletes";

export const GuidedCareTemplateActivityItemUpdateFormSchema = z.object({
  activityItemId: z.string().optional(),
  offsetInDays: z.coerce.number().min(0),
  beforeActivity: zodSchema.medicalMessage.nullish(),
  afterActivity: zodSchema.medicalMessage.nullish(),
  beforeActivityOffset: z.coerce.number().min(0).nullish(),
  afterActivityOffset: z.coerce.number().min(0).nullish(),
});

export type IGuidedCareTemplateActivityItemUpdateFormValues = z.infer<typeof GuidedCareTemplateActivityItemUpdateFormSchema>;

export const guidedCareTemplateActivityItemUpdateFormDefaultValues: Partial<IGuidedCareTemplateActivityItemUpdateFormValues> = {
  offsetInDays: undefined,
  beforeActivity: null,
  afterActivity: null,
  beforeActivityOffset: null,
  afterActivityOffset: null,
};
