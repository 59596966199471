/* eslint-disable max-lines */
import { isEqual } from "lodash";
import { createAutocompleteOption, mapToAutocompleteOptions } from "@toolkit/ui";
import {
  appointmentFrequencyTypeOptionsMap,
  codeSystemCodeOptionsMap,
  guidedCareActivityTypeOptionsMap,
  guidedCareActivityTypeToTeamMemberPositionMap,
  teamMemberPositionOptionsMap,
  isTeamMemberNonManagement,
} from "@health/enum-options";
import {
  GuidedCareActivityType,
  GuidedCareHealthProgram,
  GuidedCareHealthProgramActivity,
  GuidedCareHealthProgramActivityInput,
  GuidedCareHealthProgramInput,
  GuidedCareProgramTeamMember,
  HealthProgramTemplate,
  InputMaybe,
  Maybe,
  ServiceDetails,
  TemplateActivity,
  TemplateActivityEventItemInput,
  TemplateTeamMember,
} from "@health/queries/types";
import { IGuidedCareProgramActivityServiceUpsertFormValues } from "pages/Programs/forms/ProgramActivityServiceUpsert/GuidedCareProgramActivityServiceUpsertFormSchema";
import { IGuidedCareProgramActivityItems } from "pages/Programs/types";
import { IGuidedCareProgramActivityItemUpdateFormValues } from "../forms/ProgramActivityItemUpdate/GuidedCareProgramActivityItemUpdateFormSchema";
import { IGuidedCareProgramActivityUpsertFormValues } from "../forms/ProgramActivityUpsert/GuidedCareProgramActivityUpsertFormSchema";
import { IGuidedCareProgramMemberUpsertFormValues } from "../forms/ProgramMemberUpsert/GuidedCareProgramMemberUpsertFormSchema";
import { IGuidedCareProgramUpsertFormValues } from "../forms/ProgramUpsert/GuidedCareProgramUpsertFormSchema";
import { createMedicalMessageAutocompleteOption } from "@health/autocompletes";

export const getTeamMemberLabel = (teamMember: IGuidedCareProgramMemberUpsertFormValues) => {
  const teamMemberSpecialization =
    isTeamMemberNonManagement(teamMember?.position?.value!) && teamMember?.specialization?.label
      ? ` - ${teamMember?.specialization?.label}`
      : "";

  return `${teamMember?.position?.label}${teamMemberSpecialization}`;
};

export const convertActivityServicesToFormValues = (
  activityServices?: Maybe<Array<Maybe<ServiceDetails>>>
): IGuidedCareProgramActivityServiceUpsertFormValues[] => {
  return (
    activityServices?.map(service => ({
      serviceId: service?.id!,
      standard: codeSystemCodeOptionsMap[service?.standard!],
      service: createAutocompleteOption(
        {
          code: service?.serviceCode,
          display: service?.serviceName,
        },
        "code",
        "display"
      ),
    })) || []
  );
};

export const convertActivityItemsFormValuesToBackEndValues = (
  activityItems: IGuidedCareProgramActivityItemUpdateFormValues[]
): TemplateActivityEventItemInput[] => {
  return activityItems.map(item => {
    return {
      offsetInDays: item.offsetInDays,
      beforeCallMessageId: item.beforeActivity?.value?.id,
      beforeCallMessageOffsetDays: item.beforeActivityOffset,
      afterCallMessageId: item.afterActivity?.value?.id,
      afterCallMessageOffsetDays: item.afterActivityOffset,
    };
  });
};

export const filterTeamMembersByActivityType = (
  teamMembers: IGuidedCareProgramMemberUpsertFormValues[],
  activityType: GuidedCareActivityType
) => {
  const activityTypePositions = guidedCareActivityTypeToTeamMemberPositionMap[activityType] || [];
  const filteredTeamMembers = teamMembers?.filter(item => activityTypePositions.includes(item?.position?.value!)) || [];

  return mapToAutocompleteOptions(filteredTeamMembers, "uniqueId", getTeamMemberLabel);
};

export const convertProgramTeamMemberToFormValues = (
  templateTeamMember?: Maybe<TemplateTeamMember | GuidedCareProgramTeamMember>
): IGuidedCareProgramMemberUpsertFormValues => {
  return {
    teamMemberId: templateTeamMember?.id!,
    uniqueId: templateTeamMember?.id!,
    position: teamMemberPositionOptionsMap[templateTeamMember?.position!],
    specialization: templateTeamMember?.specialization
      ? createAutocompleteOption(
          { code: templateTeamMember?.specialization!, display: templateTeamMember?.specializationDisplay! },
          "code",
          "display"
        )
      : undefined,
    isLicencedHealthProfessional: !!templateTeamMember?.isLicencedHealthProfessional,
    isBackupPersonMandatory: !!templateTeamMember?.isBackupPersonMandatory,
    isKeyTeamMember: !!templateTeamMember?.isKeyTeamMember,
  };
};

export const convertProgramTemplateWithLatestVersionToFormValues = (
  template: HealthProgramTemplate,
  program: GuidedCareHealthProgram
): IGuidedCareProgramUpsertFormValues => {
  const _template = convertProgramTemplateToFormValues(template);
  const _program = convertProgramToFormValues(program);

  const templateTeamMembers = _template?.teamMembers || [];
  const templateActivities = _template?.activities || [];

  const programTeamMembers = _program?.teamMembers?.filter(item => !item?.templateTeamMemberId) || [];
  const teamMembers = [...templateTeamMembers, ...programTeamMembers];

  const programActivities =
    _program?.activities
      ?.filter(item => !item?.templateActivityId)
      ?.filter(item => teamMembers?.find(teamMember => teamMember?.teamMemberId === item?.teamMember?.key)) || [];

  const activities = [...templateActivities, ...programActivities];

  return {
    ..._program,
    ..._template,
    teamMembers,
    activities,
  };
};

export const convertProgramTemplateToFormValues = (template: HealthProgramTemplate) => {
  const { templateTeamMembers, templateActivities } = template;

  return {
    template: createAutocompleteOption({ id: template?.id!, name: template?.name! }, "id", "name"),
    teamMembers:
      templateTeamMembers?.map(item => ({
        ...convertProgramTeamMemberToFormValues(item),
        templateTeamMemberId: item?.id!,
      })) || [],
    activities: convertProgramTemplateActivitiesToFormValues(templateActivities, templateTeamMembers),
  };
};

export const convertProgramTemplateActivitiesToFormValues = (
  templateActivities?: Maybe<Maybe<TemplateActivity>[]>,
  templateTeamMembers?: Maybe<Maybe<TemplateTeamMember>[]>
): IGuidedCareProgramActivityUpsertFormValues[] => {
  return (
    templateActivities?.map(item => {
      const teamMember = templateTeamMembers?.find(templateTeamMember => templateTeamMember?.id === item?.templateTeamMember?.id);

      return {
        teamMember: createAutocompleteOption(convertProgramTeamMemberToFormValues(teamMember), "uniqueId", getTeamMemberLabel),
        activityServices: convertActivityServicesToFormValues(item?.serviceOfHealthProgramTemplateDetails),
        templateActivityId: item?.id!,
        templateActivityType: guidedCareActivityTypeOptionsMap[item?.templateActivityType!],
        frequencyType: appointmentFrequencyTypeOptionsMap[item?.frequencyType!],
        numberOfOccurrences: item?.numberOfOccurrences!,
        beforeActivity: item?.beforeCallMessage ? createMedicalMessageAutocompleteOption(item?.beforeCallMessage) : undefined,
        beforeActivityOffset: item?.beforeCallMessageOffsetDays!,
        afterActivity: item?.afterCallMessage ? createMedicalMessageAutocompleteOption(item?.afterCallMessage) : undefined,
        afterActivityOffset: item?.afterCallMessageOffsetDays!,
      };
    }) || []
  );
};

export const convertProgramToFormValues = (program: GuidedCareHealthProgram): IGuidedCareProgramUpsertFormValues => {
  return {
    name: program.name!,
    description: program.description!,
    price: program.price!,
    template: createAutocompleteOption(
      { id: program?.guidedCareHealthProgramTemplate?.id!, name: program?.guidedCareHealthProgramTemplate?.name! },
      "id",
      "name"
    ),
    payer: createAutocompleteOption({ id: program?.payer?.id!, name: program?.payer?.name! }, "id", "name"),
    coveredMembers:
      program?.guidedCareHealthProgramMemberLists?.map(item =>
        createAutocompleteOption({ id: item?.id!, name: item?.name! }, "id", "name")
      ) || [],
    coveredNetworks:
      program?.insuranceNetworks?.map(item => createAutocompleteOption({ id: item?.id!, name: item?.name! }, "id", "name")) || [],
    teamMembers:
      program?.teamMembers?.map(item => ({
        ...convertProgramTeamMemberToFormValues(item),
        templateTeamMemberId: item?.templateTeamMember?.id!,
      })) || [],
    activities: convertProgramActivitiesToFormValues(program.programActivities, program.teamMembers),
  };
};

export const convertProgramActivitiesToFormValues = (
  programActivities?: Maybe<Maybe<GuidedCareHealthProgramActivity>[]>,
  programTeamMembers?: Maybe<Maybe<GuidedCareProgramTeamMember>[]>
): IGuidedCareProgramActivityUpsertFormValues[] => {
  return (
    programActivities?.map(item => {
      const teamMember = programTeamMembers?.find(programTeamMember => programTeamMember?.id === item?.guidedCareProgramTeamMember?.id);

      return {
        activityId: item?.id!,
        teamMember: createAutocompleteOption(convertProgramTeamMemberToFormValues(teamMember), "uniqueId", getTeamMemberLabel),
        activityServices: convertActivityServicesToFormValues(item?.serviceOfGuidedCareHealthProgramDetails),
        templateActivityId: item?.templateActivity?.id!,
        templateActivityType: guidedCareActivityTypeOptionsMap[item?.templateActivityType!],
        frequencyType: appointmentFrequencyTypeOptionsMap[item?.frequencyType!],
        numberOfOccurrences: item?.numberOfOccurrences!,
        beforeActivity: item?.beforeCallMessage ? createMedicalMessageAutocompleteOption(item?.beforeCallMessage) : undefined,
        beforeActivityOffset: item?.beforeCallMessageOffsetDays!,
        afterActivity: item?.afterCallMessage ? createMedicalMessageAutocompleteOption(item?.afterCallMessage) : undefined,
        afterActivityOffset: item?.afterCallMessageOffsetDays!,
      };
    }) || []
  );
};

export const convertProgramActivityItemsToFormValues = (
  templateActivityItems?: IGuidedCareProgramActivityItems
): IGuidedCareProgramActivityItemUpdateFormValues[] => {
  return (
    templateActivityItems?.map(item => ({
      offsetInDays: item?.offsetInDays!,
      beforeActivity: item?.beforeCallMessage ? createMedicalMessageAutocompleteOption(item?.beforeCallMessage) : undefined,
      beforeActivityOffset: item?.beforeCallMessageOffsetDays!,
      afterActivity: item?.afterCallMessage ? createMedicalMessageAutocompleteOption(item?.afterCallMessage) : undefined,
      afterActivityOffset: item?.afterCallMessageOffsetDays!,
    })) || []
  );
};

export const convertProgramFormValuesToBackEndValues = (values: IGuidedCareProgramUpsertFormValues): GuidedCareHealthProgramInput => {
  return {
    templateName: values.template?.value?.name!,
    name: values.name!,
    description: values.description,
    price: values.price,
    payerId: values.payer?.value?.id,
    insuranceNetworks: values.coveredNetworks?.map(item => item?.value?.id),
    guidedCareHealthProgramMemberLists: values.coveredMembers?.map(item => item?.value?.id),
    teamMembers: values.teamMembers?.map(item => {
      const { templateTeamMemberId, teamMemberId, specialization, ...rest } = item;
      return {
        ...rest,
        id: teamMemberId,
        uniqueId: item.uniqueId!,
        position: item.position?.value!,
        specialization: specialization?.value?.code,
        templateTeamMember: templateTeamMemberId ? { id: templateTeamMemberId } : undefined,
        isKeyTeamMember: item?.isKeyTeamMember!,
        isBackupPersonMandatory: item?.isBackupPersonMandatory!,
        isLicencedHealthProfessional: item?.isLicencedHealthProfessional!,
      };
    }),
    programActivities: values.activities?.map(item => {
      return {
        id: item?.activityId,
        guidedCareProgramTeamMember: { uniqueId: item?.teamMember?.value?.uniqueId },
        templateActivityType: item?.templateActivityType?.value,
        frequencyType: item?.frequencyType?.value!,
        numberOfOccurrences: item.numberOfOccurrences!,
        beforeCallMessageId: item?.beforeActivity?.value?.id,
        beforeCallMessageOffsetDays: item?.beforeActivityOffset,
        afterCallMessageId: item?.afterActivity?.value?.id,
        afterCallMessageOffsetDays: item?.afterActivityOffset,
        templateActivity: item?.templateActivityId ? { id: item?.templateActivityId } : undefined,
        serviceOfGuidedCareHealthProgramDetails: item?.activityServices?.map(activityService => ({
          id: activityService?.serviceId,
          serviceCode: activityService.service?.value.code!,
          serviceName: activityService.service?.value.display!,
          standard: activityService.standard?.value!,
        })),
      };
    }),
  };
};

export const compareProgramActivities = (
  oldProgramActivities?: Maybe<Array<Maybe<GuidedCareHealthProgramActivity>>>,
  newProgramActivities?: InputMaybe<InputMaybe<GuidedCareHealthProgramActivityInput>[]>
): InputMaybe<InputMaybe<GuidedCareHealthProgramActivityInput>[]> => {
  return (
    newProgramActivities?.map(newItem => {
      const existingItem = oldProgramActivities?.find(oldItem => oldItem?.id === newItem?.id);

      const isNewItem = !existingItem;

      const isExistingItemModified =
        existingItem &&
        (!isEqual(existingItem.numberOfOccurrences, newItem?.numberOfOccurrences) ||
          !isEqual(existingItem.frequencyType, newItem?.frequencyType));

      return {
        ...newItem,
        isModified: isNewItem || isExistingItemModified,
        frequencyType: newItem?.frequencyType!,
        numberOfOccurrences: newItem?.numberOfOccurrences!,
      };
    }) || []
  );
};
