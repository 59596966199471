import { z } from "zod";
import { zodSchema } from "@health/autocompletes";
import { GuidedCareProgramActivityUpsertFormSchema } from "../ProgramActivityUpsert/GuidedCareProgramActivityUpsertFormSchema";
import { GuidedCareProgramMemberUpsertFormSchema } from "../ProgramMemberUpsert/GuidedCareProgramMemberUpsertFormSchema";

export const GuidedCareProgramUpsertFormSchema = z.object({
  name: z.string().min(1).max(70),
  template: zodSchema.guidedCareTemplate,
  description: z.string().min(1).max(255),
  price: z.coerce.number().min(0),
  payer: zodSchema.payer,
  coveredNetworks: z.array(zodSchema.healthProgramNetwork).min(1),
  coveredMembers: z.array(zodSchema.healthProgramMemberList).min(1),
  teamMembers: z.array(GuidedCareProgramMemberUpsertFormSchema),
  activities: z.array(GuidedCareProgramActivityUpsertFormSchema),
});

export type IGuidedCareProgramUpsertFormValues = z.infer<typeof GuidedCareProgramUpsertFormSchema>;

export const guidedCareProgramUpsertFormDefaultValues: Partial<IGuidedCareProgramUpsertFormValues> = {
  name: "",
  description: undefined,
  template: undefined,
  price: undefined,
  payer: undefined,
  coveredNetworks: [],
  coveredMembers: [],
  teamMembers: [],
  activities: [],
};
