import { ProviderGuidedCareTeamStatus } from "@health/queries/types";
export const getProviderGuidedCareTeamStatusColor = (status, theme) => {
    switch (status) {
        case ProviderGuidedCareTeamStatus.Approved:
            return theme.palette.success.main;
        case ProviderGuidedCareTeamStatus.Pending:
            return theme.palette.warning.main;
        case ProviderGuidedCareTeamStatus.Rejected:
            return theme.palette.error.main;
    }
};
