import { z } from "zod";
import { createZodAutocomplete } from "@toolkit/core";
import { zodSchema } from "@health/autocompletes";
import { zodEnumSchema } from "@health/enum-options";
import { GuidedCareProgramActivityServiceUpsertFormSchema } from "../ProgramActivityServiceUpsert/GuidedCareProgramActivityServiceUpsertFormSchema";
import { GuidedCareProgramMemberUpsertFormSchema } from "../ProgramMemberUpsert/GuidedCareProgramMemberUpsertFormSchema";

export const GuidedCareProgramActivityUpsertFormSchema = z.object({
  templateActivityType: zodEnumSchema.guidedCareActivityType,
  teamMember: createZodAutocomplete(GuidedCareProgramMemberUpsertFormSchema),
  numberOfOccurrences: z.coerce.number().min(1),
  frequencyType: zodEnumSchema.appointmentFrequencyType,
  activityServices: z.array(GuidedCareProgramActivityServiceUpsertFormSchema).optional(),
  beforeActivity: zodSchema.medicalMessage.nullish(),
  beforeActivityOffset: z.coerce.number().min(0).nullish(),
  afterActivity: zodSchema.medicalMessage.nullish(),
  afterActivityOffset: z.coerce.number().min(0).nullish(),
  activityId: z.string().optional(),
  templateActivityId: z.string().optional(),
});

export type IGuidedCareProgramActivityUpsertFormValues = z.infer<typeof GuidedCareProgramActivityUpsertFormSchema>;

export const guidedCareProgramActivityUpsertFormDefaultValues: Partial<IGuidedCareProgramActivityUpsertFormValues> = {
  templateActivityType: undefined,
  frequencyType: undefined,
  numberOfOccurrences: undefined,
  activityServices: [],
  beforeActivity: null,
  beforeActivityOffset: undefined,
  afterActivity: null,
  afterActivityOffset: undefined,
  teamMember: undefined,
  templateActivityId: undefined,
};
