import { isTeamMemberNonManagement } from "@health/enum-options";
import { useCustomFormContext, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomTable, Grid, PlusIcon, Typography, createAutocompleteOption, useTheme } from "@toolkit/ui";
import { getTeamMemberLabel } from "pages/Programs/others";
import { IGuidedCareProgramMemberUpsertFormEvent, IGuidedCareProgramTeamMemberItem } from "pages/Programs/types";
import { useState } from "react";
import { useFieldArray } from "react-hook-form";
import { GuidedCareProgramMemberUpsertModal } from "../../modals/ProgramMemberUpsert/GuidedCareProgramMemberUpsertModal";
import { IGuidedCareProgramUpsertFormValues } from "../ProgramUpsert/GuidedCareProgramUpsertFormSchema";
import { useGuidedCareProgramMembersColumns } from "./useGuidedCareProgramMembersColumns";
import { useGuidedCareProgramMembersFormStyle } from "./useGuidedCareProgramMembersFormStyle";

export const GuidedCareProgramMembersForm = () => {
  const [selectedMember, setSelectedMember] = useState<IGuidedCareProgramTeamMemberItem>();

  const { t } = useTranslation("gcadmin");
  const { open, handleOpen, handleClose } = useOpenState();

  const { classes } = useGuidedCareProgramMembersFormStyle();

  const form = useCustomFormContext<IGuidedCareProgramUpsertFormValues>();
  const { watch, control, setValue, isFormDisabled } = form;

  const activities = watch("activities");

  const { fields, append, remove, update } = useFieldArray<IGuidedCareProgramUpsertFormValues, "teamMembers">({
    control,
    name: "teamMembers",
  });

  const onAddClick = () => {
    setSelectedMember(undefined);
    handleOpen();
  };

  const onGuidedCareProgramMemberUpsertModalChange = (event: IGuidedCareProgramMemberUpsertFormEvent) => {
    if (event.type === "CREATE") {
      append(event.payload.values);
      handleClose();
    } else if (event.type === "UPDATE" && selectedMember) {
      const updatedFieldIndex = fields.findIndex(item => item.id === selectedMember.id);
      if (updatedFieldIndex > -1) {
        const updatedTeaMember = { ...fields[updatedFieldIndex], ...event.payload.values };
        update(updatedFieldIndex, updatedTeaMember);

        // Update activities associated with the updated member
        const updatedActivities = activities.map(item =>
          item.teamMember?.value?.uniqueId === updatedTeaMember.uniqueId
            ? { ...item, teamMember: createAutocompleteOption(updatedTeaMember, "uniqueId", getTeamMemberLabel) }
            : item
        );
        setValue("activities", updatedActivities);
      }
      handleClose();
    }
  };

  const onEditRowClick = (item: IGuidedCareProgramTeamMemberItem) => {
    setSelectedMember(item);
    handleOpen();
  };

  const onDeleteRowClick = (item: IGuidedCareProgramTeamMemberItem) => {
    const itemIndex = fields.findIndex(field => field.id === item.id);
    if (itemIndex > -1) {
      remove(itemIndex);

      // Remove activities associated with the deleted member
      const updatedActivities = activities.filter(activity => activity.teamMember?.value?.uniqueId !== fields[itemIndex]?.uniqueId);
      setValue("activities", updatedActivities);
    }
  };
  const theme = useTheme();
  return (
    <Grid container spacing={2}>
      {open && (
        <GuidedCareProgramMemberUpsertModal
          isOpen={open}
          onClose={handleClose}
          member={selectedMember}
          onChange={onGuidedCareProgramMemberUpsertModalChange}
        />
      )}

      <Grid item xs={12}>
        <Grid container>
          <Grid xs={8} lg={10}>
            <Typography className={classes.title}> {t("Care Team Members")} </Typography>
          </Grid>

          {!isFormDisabled && (
            <Grid xs={4} lg={2}>
              <Button fullWidth onClick={onAddClick} startIcon={<PlusIcon fill={theme.palette.common.white} />}>
                {t("Add Member")}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <CustomTable
          data={fields}
          columns={useGuidedCareProgramMembersColumns()}
          isEditVisible
          isDeleteVisible
          isRowEditable={({ templateTeamMemberId }) => !isFormDisabled && !templateTeamMemberId}
          isRowDeletable={({ position, templateTeamMemberId }) =>
            !isFormDisabled && !templateTeamMemberId && isTeamMemberNonManagement(position?.value)
          }
          onEditRow={onEditRowClick}
          onDeleteRow={onDeleteRowClick}
          hasFooter={false}
          scrollable
        />
      </Grid>
    </Grid>
  );
};
