import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useHealthParameterCategoriesAutocompleteQuery } from "./gql";
export const createHealthParameterCategoryAutocompleteOption = (category) => {
    return createAutocompleteOption({
        id: category === null || category === void 0 ? void 0 : category.id,
        code: category === null || category === void 0 ? void 0 : category.code,
        display: category === null || category === void 0 ? void 0 : category.display,
        arabicDisplay: category === null || category === void 0 ? void 0 : category.arabicDisplay,
    }, "id", item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.display, item === null || item === void 0 ? void 0 : item.arabicDisplay));
};
export const getHealthParameterCategoriesAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "getHealthParameterCategories",
        query: useHealthParameterCategoriesAutocompleteQuery,
        filtersForQuery: {},
        labelBy: item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.display, item === null || item === void 0 ? void 0 : item.arabicDisplay),
        backendAccessor: "id",
        filterSearchKey: "nameContains",
    });
};
