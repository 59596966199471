import { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { uniqueId } from "lodash";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormCheckbox, Grid } from "@toolkit/ui";
import { SystemCodeAutocomplete } from "@health/autocompletes";
import { doesTeamMemberHaveSpecialization, teamMemberNonManagementOptions, isTeamMemberNonManagement } from "@health/enum-options";
import { CodeSystemCode } from "@health/queries/types";
import { IGuidedCareProgramMemberUpsertFormEvent } from "pages/Programs/types";
import {
  GuidedCareProgramMemberUpsertFormSchema,
  IGuidedCareProgramMemberUpsertFormValues,
  guidedCareProgramMemberUpsertFormDefaultValues,
} from "../ProgramMemberUpsert/GuidedCareProgramMemberUpsertFormSchema";

type GuidedCareProgramMemberUpsertFormProps = {
  member?: IGuidedCareProgramMemberUpsertFormValues;
  onChange: (event: IGuidedCareProgramMemberUpsertFormEvent) => void;
};

export type GuidedCareProgramMemberUpsertFormRef = {
  getForm: () => UseFormReturn<IGuidedCareProgramMemberUpsertFormValues>;
  submit: () => void;
};

const GuidedCareProgramTeamMemberUpsertFormForwardRef: ForwardRefRenderFunction<
  GuidedCareProgramMemberUpsertFormRef,
  GuidedCareProgramMemberUpsertFormProps
> = (props, ref) => {
  const { member, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const form = useCustomForm<IGuidedCareProgramMemberUpsertFormValues>({
    defaultValues: guidedCareProgramMemberUpsertFormDefaultValues,
    schema: GuidedCareProgramMemberUpsertFormSchema,
  });

  const { watch, setValues, handleSubmit } = form;

  const position = watch("position")?.value;

  const onSubmit = (values: IGuidedCareProgramMemberUpsertFormValues) => {
    if (member) {
      onChange({
        type: "UPDATE",
        payload: { values },
      });
    } else {
      onChange({
        type: "CREATE",
        payload: {
          values: {
            ...values,
            uniqueId: uniqueId("member_"),
          },
        },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (member) {
      setValues(member);
    }
  }, [member, setValues]);

  return (
    <FormProvider {...form}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormAutocomplete
              name={"position"}
              label={t("Position")}
              options={teamMemberNonManagementOptions}
              disabled={position && !isTeamMemberNonManagement(position)}
            />
          </Grid>

          <Grid item xs={6}>
            <SystemCodeAutocomplete
              name={"specialization"}
              isCodeHidden
              filter={{ codeSystemCode: CodeSystemCode.Speciality }}
              disabled={!position || !doesTeamMemberHaveSpecialization(position)}
            />
          </Grid>

          <Grid item xs={6}>
            <FormCheckbox name={"isLicencedHealthProfessional"} label={t("Licensed Health Professional")} />
          </Grid>

          <Grid item xs={6}>
            <FormCheckbox name={"isBackupPersonMandatory"} label={t("Backup Person Mandatory")} />
          </Grid>

          <Grid item xs={12}>
            <FormCheckbox name={"isKeyTeamMember"} label={t("Key Team Member")} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export const GuidedCareProgramMemberUpsertForm = forwardRef(GuidedCareProgramTeamMemberUpsertFormForwardRef);
