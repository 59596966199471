import { FC } from "react";
import { FormProvider } from "react-hook-form";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, FormAutocomplete, Grid, PlusIcon } from "@toolkit/ui";
import { SystemCodeAutocomplete } from "@health/autocompletes";
import { serviceSystemCodeOptions } from "@health/enum-options";
import { IGuidedCareProgramActivityServiceUpsertFormEvent } from "pages/Programs/types";
import {
  GuidedCareProgramActivityServiceUpsertFormSchema,
  IGuidedCareProgramActivityServiceUpsertFormValues,
  guidedCareProgramActivityServiceUpsertFormDefaultValues,
} from "./GuidedCareProgramActivityServiceUpsertFormSchema";

type GuidedCareActivityServiceUpsertFormProps = {
  onChange: (event: IGuidedCareProgramActivityServiceUpsertFormEvent) => void;
};

export const GuidedCareProgramActivityServiceUpsertForm: FC<GuidedCareActivityServiceUpsertFormProps> = props => {
  const { onChange } = props;

  const { t } = useTranslation("gcadmin");

  const form = useCustomForm<IGuidedCareProgramActivityServiceUpsertFormValues>({
    defaultValues: guidedCareProgramActivityServiceUpsertFormDefaultValues,
    schema: GuidedCareProgramActivityServiceUpsertFormSchema,
  });

  const { watch, reset, handleSubmit } = form;

  const standard = watch("standard")?.value;

  const onSubmit = (values: IGuidedCareProgramActivityServiceUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });

    reset();
  };

  return (
    <FormProvider {...form}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormAutocomplete name={"standard"} label={t("Standard")} options={serviceSystemCodeOptions} />
          </Grid>

          <Grid item xs={7}>
            <SystemCodeAutocomplete name={"service"} label={t("Specialization")} skip={!standard} filter={{ codeSystemCode: standard }} />
          </Grid>

          <Grid item xs={2}>
            <Button onClick={handleSubmit(onSubmit)} fullWidth startIcon={<PlusIcon />}>
              {t("Add")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
