import { z } from "zod";
import { zodSchema } from "@health/autocompletes";

export const GuidedCareProgramActivityItemUpdateFormSchema = z.object({
  offsetInDays: z.coerce.number().min(0),
  beforeActivity: zodSchema.medicalMessage.nullish(),
  afterActivity: zodSchema.medicalMessage.nullish(),
  beforeActivityOffset: z.coerce.number().min(0).nullish(),
  afterActivityOffset: z.coerce.number().min(0).nullish(),
});

export type IGuidedCareProgramActivityItemUpdateFormValues = z.infer<typeof GuidedCareProgramActivityItemUpdateFormSchema>;

export const guidedCareProgramActivityItemUpdateFormDefaultValues: Partial<IGuidedCareProgramActivityItemUpdateFormValues> = {
  offsetInDays: undefined,
  beforeActivity: null,
  afterActivity: null,
  beforeActivityOffset: null,
  afterActivityOffset: null,
};
