import { MedicalFormActivation } from "@health/domains";
import { getAutocompleteEnumFilter, medicalFormCategoryOptionsMap, medicalFormTypeOptionsMap } from "@health/enum-options";
import { MedicalForm, MedicalFormSortingField } from "@health/queries/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, OrderDirection, TruncateTypography } from "@toolkit/ui";
import { useMemo } from "react";

export const useAdminMedicalFormsTableColumns = (): CustomTableColumnProps<MedicalForm>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name", { ns: "gcadmin" }),
        accessor: ({ name, nameAr }) => <TruncateTypography text={pickLocalizedValue(name!, nameAr!)} />,
        type: "string",
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: MedicalFormSortingField.Name,
        filter: {
          type: "string",
          name: "nameContains",
        },
      },
      {
        key: "code",
        header: t("Code", { ns: "gcadmin" }),
        accessor: "code",
        type: "string",
        filter: {
          type: "string",
          name: "codes",
          getValueForBackend: name => (name ? [name] : undefined),
        },
      },
      {
        key: "type",
        header: t("Type", { ns: "gcadmin" }),
        accessor: ({ type }) => medicalFormTypeOptionsMap[type!]?.label,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: MedicalFormSortingField.Type,
        filter: getAutocompleteEnumFilter("MedicalFormType", "type"),
      },
      {
        key: "category",
        header: t("Category", { ns: "gcadmin" }),
        accessor: ({ category }) => medicalFormCategoryOptionsMap[category!]?.label,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: MedicalFormSortingField.Category,
        filter: getAutocompleteEnumFilter("MedicalFormCategory", "category"),
      },
      {
        key: "active",
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: MedicalFormSortingField.Active,
        header: t("Active", { ns: "gcadmin" }),
        accessor: ({ id, active }) => <MedicalFormActivation id={id!} isActive={!!active} />,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ];
  }, [t]);
};
