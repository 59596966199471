import { chartTypeOptionsMap, templateFieldTypeOptionsMap } from "@health/enum-options";
import { HealthParameter } from "@health/queries/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, TruncateTypography } from "@toolkit/ui";
import { useMemo } from "react";
import { HealthParameterDefaultRangeValuesShowButton } from "../HealthParameterDefaultRangeValuesShowButton/HealthParameterDefaultRangeValuesShowButton";

export const useHealthParameterCustomTableListColumns = (): CustomTableColumnProps<HealthParameter>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "standardCode",
        header: t("Standard Code"),
        accessor: "standardCode",
      },
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
      },
      {
        key: "display",
        header: t("Display"),
        accessor: ({ display, arabicDisplay }) => <TruncateTypography text={pickLocalizedValue(display!, arabicDisplay!)} />,
      },
      {
        key: "category",
        header: t("Category"),
        accessor: ({ category }) => pickLocalizedValue(category?.display!, category?.arabicDisplay!),
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => templateFieldTypeOptionsMap[type!]?.label,
      },
      {
        key: "chartType",
        header: t("Chart Type"),
        accessor: ({ chartType }) => chartTypeOptionsMap[chartType!]?.label,
      },
      {
        key: "unit",
        header: t("Unit"),
        accessor: ({ unit }) => pickLocalizedValue(unit?.display!, unit?.arabicDisplay!),
      },
      {
        key: "referenceRange",
        header: t("Reference Range"),
        accessor: ({ referenceRange }) => referenceRange || "-",
      },
      {
        key: "defaultRangeValues",
        header: t("Default Range Values"),
        accessor: ({ type, defaultValue, minNormalRangeValue, maxNormalRangeValue, allowedValues }) => (
          <HealthParameterDefaultRangeValuesShowButton
            type={type!}
            defaultValue={defaultValue!}
            minNormalRangeValue={minNormalRangeValue!}
            maxNormalRangeValue={maxNormalRangeValue!}
            allowedValues={allowedValues as string[]}
          />
        ),
      },
    ];
  }, [t]);
};
