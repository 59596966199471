import { z } from "zod";
import { createZodAutocomplete } from "@toolkit/core";
import { zodSchema } from "@health/autocompletes";
import { zodEnumSchema } from "@health/enum-options";
import { GuidedCareTemplateActivityServiceUpsertFormSchema } from "../TemplateActivityServiceUpsert/GuidedCareTemplateActivityServiceUpsertFormSchema";
import { GuidedCareTemplateMemberUpsertFormSchema } from "../TemplateMemberUpsert/GuidedCareTemplateMemberUpsertFormSchema";

export const guidedCareTemplateInterventionActivityUpsertFormSchema = z.object({
  templateActivityType: zodEnumSchema.guidedCareActivityType,
  teamMember: createZodAutocomplete(GuidedCareTemplateMemberUpsertFormSchema),
  numberOfOccurrences: z.coerce.number().min(1),
  medicalForm: zodSchema.medicalForm.nullish(),
  medicalMessage: zodSchema.medicalMessage.nullish(),
  frequencyType: zodEnumSchema.appointmentFrequencyType,
  activityServices: z.array(GuidedCareTemplateActivityServiceUpsertFormSchema).optional(),
  beforeActivity: zodSchema.medicalMessage.nullish(),
  beforeActivityOffset: z.coerce.number().min(0).nullish(),
  afterActivity: zodSchema.medicalMessage.nullish(),
  afterActivityOffset: z.coerce.number().min(0).nullish(),
  activityId: z.string().optional(),
});

export type IGuidedCareTemplateInterventionActivityUpsertFormValues = z.infer<
  typeof guidedCareTemplateInterventionActivityUpsertFormSchema
>;

export const guidedCareTemplateInterventionActivityUpsertFormDefaultValues: Partial<IGuidedCareTemplateInterventionActivityUpsertFormValues> =
  {
    templateActivityType: undefined,
    frequencyType: undefined,
    numberOfOccurrences: undefined,
    medicalForm: undefined,
    medicalMessage: undefined,
    activityServices: [],
    beforeActivity: null,
    beforeActivityOffset: undefined,
    afterActivity: null,
    afterActivityOffset: undefined,
    teamMember: undefined,
  };
