import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useGuidedCareProgramsAutocompleteQuery } from "./gql";
export const createGuidedCareProgramAutocompleteOption = (program) => {
    return createAutocompleteOption({ id: program === null || program === void 0 ? void 0 : program.id, name: program === null || program === void 0 ? void 0 : program.name }, "id", "name");
};
export const getGuidedCareProgramsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "guidedCareHealthPrograms",
        query: useGuidedCareProgramsAutocompleteQuery,
        labelBy: "name",
        backendAccessor: "id",
        filtersForQuery: {
            name_Icontains: "",
        },
    });
};
