import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useHealthConditionsAutocompleteQuery } from "./gql";
export const createHealthConditionAutocompleteOption = (healthCondition) => {
    return createAutocompleteOption({
        id: healthCondition === null || healthCondition === void 0 ? void 0 : healthCondition.id,
        code: healthCondition === null || healthCondition === void 0 ? void 0 : healthCondition.code,
        display: healthCondition === null || healthCondition === void 0 ? void 0 : healthCondition.display,
        arabicDisplay: healthCondition === null || healthCondition === void 0 ? void 0 : healthCondition.arabicDisplay,
    }, "id", item => pickLocalizedValue(item === null || item === void 0 ? void 0 : item.display, item === null || item === void 0 ? void 0 : item.arabicDisplay));
};
export const getHealthConditionsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "getHealthConditions",
        query: useHealthConditionsAutocompleteQuery,
        labelBy: "display",
        backendAccessor: "id",
        filterSearchKey: "display",
    });
};
